import { Col, Form, Input, Row, Select, Button, notification, Switch, Space, InputNumber } from 'antd';
import App from '../../componentes/Modal';
import TabelaLojas from '../../componentes/TabelaLojas';
import TabelaEmissor from '../../componentes/TabelaEmissor';
import TabelaUsuariosPorLoja from '../../componentes/TabelaUsuariosPorLoja';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import useLoja from '../../core/hooks/useLoja';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useRef, useState } from 'react';
import { Authentication } from '../../auth/Auth';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';
import { Cidades } from '../../sdk/@types/Cidades';
import CidadeService from '../../sdk/services/Cidade.service';
import CozinhaService from '../../sdk/services/Cozinha.service';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { UserService, Usuarios } from '../../sdk';
import TabelaTerminaisPorEmissor from '../../componentes/TabelaTerminaisPorEmissor';
import EstadoService from '../../sdk/services/Estado.service';
import { Estados } from '../../sdk/@types/Estados';
import Skeleton from 'antd/lib/skeleton';

interface FormularioDeLojaProps {
  loja?: Lojas.formRestaurante;
}

export default function FormularioDeLoja(props: FormularioDeLojaProps) {
  usePageTitle('Lista de Lojas');
  const access_token = localStorage.getItem('accessToken');
  const [fetching, setFetching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);
  const [recarregarCidades, setRecarregarCidades] = useState<boolean>(true);
  const [recarregarTabela, setRecarregarTabela] = useState<boolean>(true);
  const [recarregarTabelaEmissor, setRecarregarTabelaEmissor] = useState<boolean>(true);
  const [recarregartabelaTerminais, setRecarregartabelaTerminais] =
    useState<boolean>(true);
  const params = useParams<{ id: string }>();
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );
  const { fetchLoja } = useLoja();

  const [checkedAberto, setCheckedAberto] = useState(
    props.loja ? props.loja.aberto : true
  );
  const [checkedAtivo, setCheckedAtivo] = useState(props.loja ? props.loja.ativo : true);

  const [checkedBloqueio, setCheckedBloqueio] = useState(
    props.loja ? props.loja.bloqueio : false
  );
  const [cidades, setCidades] = useState<Cidades.Cidade>();
  const [estados, setEstados] = useState<Estados.Estado>();
  const [cozinhas, setCozinhas] = useState<Cozinhas.Cozinha>();
  const [cidadeEscolida, setCidadeEscolida] = useState<string>();
  const [estadoSelecionado, setEstadoSelecionado] = useState<string>('');

  const [temCidade, setTemCidade] = useState<boolean>(false);

  const [flag, setFlag] = useState<boolean>(
    props.loja ? props.loja?.flag_Techsapp_Sincronizado : false
  );

  const [emissor, setEmissor] = useState<Lojas.Emissor>();
  const [checkedAtivoEmissor, setCheckedAtivoEmissor] = useState(
    emissor ? emissor.ativo : true
  );

  const refToken = useRef(null);
  const refTokenCidade = useRef(null);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    EstadoService.getAll()
      .then(setEstados)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  useEffect(() => {
    EstadoService.buscarPorUF(props.loja?.estado ? props.loja?.estado : '')
      .then((resp: any) => {
        setEstadoSelecionado(resp._embedded.estados[0].id);
      })

      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      setCidades(undefined);
      CidadeService.listarPorEstado(estadoSelecionado)
        .then(tratarDados)
        .then(setCidades)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [estadoSelecionado]);

  useEffect(() => {
    CozinhaService.getAll()
      .then(tratarDadosCozinha)
      .then(setCozinhas)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  useEffect(() => {
    let cnpjAux = props.loja?.cnpj.replaceAll('.', '');
    cnpjAux = cnpjAux?.replaceAll('/', '');
    cnpjAux = cnpjAux?.replaceAll('-', '');
    if (cnpjAux) {
      LojaService.getEmissorByCnpj(cnpjAux)
        .then(setEmissor)
        .catch((error) => {
          setError(new Error(error.message));
        });

      setRecarregarTabelaEmissor(false);
      setRecarregarTabelaEmissor(true);
    }
  }, [props.loja?.cnpj, reload]);

  const { Option } = Select;

  const onChange = (value: number) => {
    //console.log(`selected ${value}`);
  };
  const onChangeUf = (value: string) => {
    //console.log(`Selecionando uf ${value}`);
    setEstadoSelecionado(value);

    const selectCidade = refTokenCidade.current;
  };

  const onSearch = (value: string) => {
    //console.log('search:', value);
  };

  function tratarDados(dados: Cidades.Cidade): Cidades.Cidade {
    if (dados?._embedded) {
      setTemCidade(true);
    } else {
      setTemCidade(false);
    }

    return dados;
  }

  function reloadEmissor() {
    setReload(!reload);
  }

  function tratarDadosCozinha(dados: Cozinhas.Cozinha): Cozinhas.Cozinha {
    return dados;
  }

  const history = useHistory();
  const tabela = <TabelaUsuariosPorLoja></TabelaUsuariosPorLoja>;
  const tabelaEmissor = (
    <TabelaEmissor
      aoCadastrar={reloadEmissor}
      cnpj={props.loja?.cnpj ? props.loja?.cnpj : '0'}
    ></TabelaEmissor>
  );
  const tabelaTerminais = !emissor ? (
    <Skeleton></Skeleton>
  ) : (
    <TabelaTerminaisPorEmissor
      cnpj={props.loja?.cnpj}
      emissor={emissor}
    ></TabelaTerminaisPorEmissor>
  );

  const separarNumeroLogradouro = (endereco: any) => {
    const regex = /^(.*?)(?:,\s*)?(\d+)\s*(.*)/;
    const match = endereco.match(regex);

    if (match && match.length === 4) {
      const logradouro = match[1].trim();
      const numero = match[2];
      return { logradouro, numero };
    } else {
      return null;
    }
  };

  return (
    <>
      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (loja: Lojas.formLojas) => {
          console.log(loja);
        
          setFetching(true);
          loja.ativo = checkedAtivo;
          loja.bloqueio = checkedBloqueio;
          loja.aberto = checkedAberto;

          let a = 'b';

          cidades?._embedded.cidades.map((cidade) =>
            cidade.nome === loja.cidade ? (loja.cidade = '' + cidade.id) : ''
          );
          const numEnd = separarNumeroLogradouro(loja.endereco);
          console.log(numEnd);

          let restauranteInput: Lojas.RestauranteInput = {
            aberto: loja.aberto,
            api_escrita: 'string',
            api_leitura: 'string',
            ativo: loja.ativo,
            basic_token: loja.basic_token,
            bloqueio: loja.bloqueio,
            cnpj: loja.cnpj,
            cozinha: {
              id: loja.cozinha,
            },
            dominio: loja.dominio,
            endereco: {
              bairro: loja.bairro,
              cep: loja.cep,
              cidade: {
                id: loja.cidade,
              },
              complemento: '',
              logradouro: numEnd?.logradouro,
              numero: numEnd?.numero ? Number(numEnd?.numero) : 0,
            },
            ie: loja.ie,
            nome: loja.nome,
            taxaFrete: Number(loja.taxaFrete),
            flag_Techsapp_Sincronizado: flag?flag:false,
            tp_amb: loja.tp_amb,
            crt: loja.crt,
            linhasTechsbot:loja.linhasTechsbot
          };

          try {
            if (props.loja) {
              let arrayIdUsuarios: string[] = [];

              // buscar lista de usuarios
              let responsaveisLoja: Usuarios.ListaUsuarios =
                await LojaService.getResponsaveisDaLoja(params.id);

              if (responsaveisLoja._embedded) {
                responsaveisLoja._embedded.usuarios.map((usuario) => {
                  arrayIdUsuarios.push(usuario.id);

                  if (loja.bloqueio) {
                    UserService.bloquearUsuario(usuario.id);
                  }
                });
              }

              if (!loja.ativo) {
                await UserService.inativarUsuarios(arrayIdUsuarios);
              }
              if (loja.bloqueio || !loja.ativo) {
                setRecarregarTabela(false);
                setRecarregarTabela(true);

                setRecarregartabelaTerminais(false);
                setRecarregartabelaTerminais(true);
              }

              let lojaCadastrada: Lojas.RestauranteModel = await LojaService.editarLoja(
                restauranteInput,
                params.id
              );
            } else {
              let lojaCadastrada: Lojas.RestauranteModel =
                await LojaService.cadastrarLoja(restauranteInput);
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, '3a40a886-0a6e-427a-bf56-5ead376c2032');
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, '7a8d747f-465d-47fe-b55e-4b7a00730f77');
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, 'c5c38162-eabc-4e2f-93b6-8654a5cc6574');
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, '9114d2ea-58e8-4ea3-95db-9023019287b0');
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, 'cf8b3e93-811b-48d1-a226-c05a603ca9a4');
                await LojaService.cadastrarFormaDePagamento(lojaCadastrada.id, '7985e985-d9dd-4965-8141-164bb1f3cc06');
                
      
              history.push(`/lojas/editar/${lojaCadastrada.id}`);
            }

            if (props.loja) {
              notification.success({
                message: 'Sucesso',
                description: 'Loja editada com sucesso',
                top: 80,
              });
            } else {
              notification.success({
                message: 'Sucesso',
                description: 'Loja criada com sucesso',
                top: 80,
              });
            }

           /* if (emissor) {
              try {
                let emissorAtualizado = await LojaService.getEmissorByCnpj(
                  props.loja?.cnpj ? props.loja?.cnpj : '0'
                );
                emissorAtualizado.ativo = checkedAtivoEmissor;
                const emissorAtualizadoBody: any = {
                  ativo: emissorAtualizado.ativo,
                  chaveDeAcesso: emissorAtualizado.chaveDeAcesso,
                  chaveHomolog:emissorAtualizado.chaveHomolog,
                  cnpj: restauranteInput.cnpj,
                  id: emissorAtualizado.id,
                  status_emissao:emissorAtualizado.status_emissao
                };

                if (emissor) {
                  let emissorAux: any = await LojaService.updateEmissor(
                    emissorAtualizadoBody,
                    emissorAtualizado.id
                  );
                }
              } catch (error) {
                setFetching(false);
              }
            }*/
          } catch (error) {
            setFetching(false);
          }
          setFetching(false);
        }}
        initialValues={props.loja}
      >
        <Row align={'middle'}>
          <legend
            style={{
              borderTop: '1px solid #000',
              margin: '0',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Identificação'}
          </legend>
          <fieldset
            style={{
              width: ' 100%',
              display: 'flex',
              padding: '20px',
              gap: '10px',
            }}
          >
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Nome'}
                name={'nome'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  style={{
                    width: '95%',
                  }}
                  placeholder={'E.x.: Thai Gourmeth'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>
              <Form.Item
                label={'Cnpj'}
                name={'cnpj'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: 00.000.000/0000-00'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>
              <Form.Item
                label={'ie'}
                name={'ie'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O ie não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  type='number'
                  placeholder={'E.x.: xxxxxxxxxxx'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>

              <Form.Item
                label={'BasicToken'}
                name={'basic_token'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: xxxxxxxxxxxxxx'}
                  ref={refToken}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Domínio'}
                name={'dominio'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O dominio não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: homologacao'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>
              <Form.Item label={'Ramo'} name={'cozinha'}>
                <Select
                  placeholder={'Selecione o ramo'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                >
                  {cozinhas?._embedded.cozinhas.map((cozinha) => (
                    <Select.Option value={cozinha.id}>{cozinha.nome}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={'Frete'}
                name={'taxaFrete'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: 10.50'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>

              <Form.Item
                label={'Sincronização'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  disabled={flag}
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    if (props.loja) {
                      let valorToken: any = refToken.current;

                      let respImportarLojaV1 = await LojaService.importarLoja(
                        valorToken.state.value,
                        props.loja?.dominio
                      );

                      if (respImportarLojaV1 === true) {
                        if (props.loja) {
                          const lojaAtual: any = await LojaService.getRestauranteById(
                            params.id
                          );
                          console.log(lojaAtual);
                          let restauranteAux: Lojas.RestauranteInput = {
                            aberto: lojaAtual.aberto,
                            api_escrita: 'string',
                            api_leitura: 'string',
                            ativo: lojaAtual.ativo,
                            basic_token: lojaAtual.basic_token,
                            bloqueio: lojaAtual.bloqueio,
                            cnpj: lojaAtual.cnpj,
                            cozinha: {
                              id: lojaAtual.cozinha.id,
                            },
                            dominio: lojaAtual.dominio,
                            endereco: {
                              bairro: lojaAtual.endereco.bairro,
                              cep: lojaAtual.endereco.cep,
                              cidade: {
                                id: lojaAtual.endereco.cidade.id,
                              },
                              complemento: lojaAtual.endereco.complemento,
                              logradouro: lojaAtual.endereco.logradouro,
                              numero: lojaAtual.endereco.numero,
                            },
                            ie: lojaAtual.ie,
                            nome: lojaAtual.nome,
                            taxaFrete: Number(lojaAtual.taxaFrete),
                            flag_Techsapp_Sincronizado: true,
                            tp_amb: lojaAtual.tp_amb,
                            crt: lojaAtual.crt,
                            linhasTechsbot:lojaAtual.linhasTechsbot
                          };

                          if (restauranteAux) {
                            let setarFlag = await LojaService.editarLoja(
                              restauranteAux,
                              params.id
                            );
                            setLoading(false);
                            if (setarFlag.flag_Techsapp_Sincronizado == true) {
                              setFlag(true);
                            }
                            notification.success({
                              message: 'Sucesso',
                              description: 'Importação em andamento!',
                              top: 80,
                            });
                          }
                          let respImportarProdutos = LojaService.importarProdutosTechsapp(
                            lojaAtual.id,
                            lojaAtual.dominio,
                            valorToken.state.value
                          );
                        }
                      }
                    } else {
                      setLoading(false);
                      notification.info({
                        message: 'Loja Inexistente',
                        description: 'Crie uma loja antes de sincronizar!!',
                        top: 100,
                      });
                    }
                  }}
                >
                  {props.loja?.flag_Techsapp_Sincronizado
                    ? 'Loja Sincronizada'
                    : 'Importar loja v1'}
                </Button>
              </Form.Item>
            </Col>
          </fieldset>
          <legend
            style={{
              borderTop: '1px solid #000',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Endereço'}
          </legend>
          <fieldset
            style={{
              width: ' 99%',
              display: 'flex',
            }}
          >
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Cep'}
                name={'cep'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O ´cep não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: 00000-000'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>

              <Form.Item
                label={'Endereço'}
                name={'endereco'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O endereço não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: xxxxxxxx'}
                  type='text'
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>

              <Form.Item label={'Cidade'} name={'cidade'}>
                <Select
                  placeholder={'Selecione a Cidade'}
                  showSearch
                  onChange={onChange}
                  onSearch={onSearch}
                  ref={refTokenCidade}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {cidades?._embedded.cidades.map((cidade) => (
                    <Option value={cidade.id}>{cidade.nome}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Bairro'}
                name={'bairro'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O bairro não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  placeholder={'E.x.: Belem'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>

              <Form.Item label={'Estado'} name={'estado'}>
                <Select
                  placeholder={'Selecione o Estado'}
                  showSearch
                  onChange={onChangeUf}
                  onSearch={onSearch}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                >
                  {estados?._embedded.estados.map((estado) => (
                    <Option value={estado.id}>{estado.uf}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </fieldset>

          <legend
            style={{
              borderTop: '1px solid #000',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Campos Fiscais'}
          </legend>
          <fieldset
            style={{
              width: ' 99%',
              display: 'flex',
              gap: '5px',
            }}
          >
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Regime Tributário'}
                name={'crt'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione o Regime'}
                  showSearch
                  onChange={onChange}
                  onSearch={onSearch}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value='1'>Simples Nacional</Option>
                  <Option value='2'>Simples Nacional Exceção</Option>
                  <Option value='3'>Normal</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Ambiente'}
                name={'tp_amb'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione o Ambiente'}
                  showSearch
                  onChange={onChange}
                  onSearch={onSearch}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value='1'>Produção</Option>
                  <Option value='2'>Homologação</Option>
                </Select>
              </Form.Item>
            </Col>
          </fieldset>
          <legend
            style={{
              borderTop: '1px solid #000',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Techsbot'}
          </legend>
          <fieldset
            style={{
              width: ' 99%',
              display: 'flex',
            }}
          >
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Linhas Techsbot'}
                name={'linhasTechsbot'}
              >
                <InputNumber
                style={{width:'100%'}}
                  placeholder={'E.x.: 5'}
                  disabled={
                    !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                  }
                />
              </Form.Item>
            </Col>
          </fieldset>
          <legend
            style={{
              borderTop: '1px solid #000',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Funções'}
          </legend>
          <fieldset
            style={{
              width: ' 99%',
              display: 'flex',
            }}
          >
            <Col xs={24}>
              <Row justify={'center'} style={{ gap: '50px' }}>
                <Form.Item label='Estabelecimento' name={'aberto'}>
                  <Switch
                    checkedChildren={'Aberto'}
                    unCheckedChildren={'Fechado'}
                    defaultChecked
                    checked={checkedAberto}
                    onChange={setCheckedAberto}
                  />
                </Form.Item>
                <Form.Item label='Status' name={'ativo'}>
                  <Switch
                    checkedChildren={'Ativo'}
                    unCheckedChildren={'Inativo'}
                    defaultChecked
                    checked={checkedAtivo}
                    onChange={setCheckedAtivo}
                  />
                </Form.Item>

                <Form.Item label='Situação' name={'bloqueio'}>
                  <Switch
                    checked={checkedBloqueio}
                    onChange={setCheckedBloqueio}
                    checkedChildren={'Bloqueado'}
                    unCheckedChildren={'Desbloqueado'}
                  />
                </Form.Item>
              </Row>
            </Col>
          </fieldset>
          <Col xs={24}>
            <Row justify={'end'}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                loading={fetching}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                Salvar Loja
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>

      {recarregarTabela ? tabela : ''}
      {recarregarTabelaEmissor ? tabelaEmissor : ''}
      {recarregartabelaTerminais ? tabelaTerminais : ''}
    </>
  );
}
